import React from 'react';
import axios from 'axios';
import Modal from 'react-bootstrap/Modal';
import Button from 'react-bootstrap/Button';
import Form from 'react-bootstrap/Form';


class Suggestion extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
            modalShow: false,
            email: '',
            desc: '',
            formShow: true,
        };
    }

    handleExited = () => {
        this.setState({formShow: true});
    };

    handleHide = (event) => {
        this.setState({modalShow: false});
    };

    handleSubmit = (event) => {
        const form = event.currentTarget;
        const that = this;

        event.preventDefault();

        const suggestionData = {
            email: this.state.email,
            desc: this.state.desc,
        };

        axios.post('/api/v1/predictions/suggestion', suggestionData)
        .then(function() {
            that.setState({formShow: false});
        })

    };

    render() {
        return (
            <div className='suggestion__form d-flex justify-content-center'>
                <Button className='suggestion' onClick={() => this.setState({modalShow: true})} variant='secondary'>
                    Мои шутки лучше!
                </Button>
                <Modal
                    className='suggestion'
                    onHide={this.handleHide}
                    onExited={this.handleExited}
                    show={this.state.modalShow}
                    aria-labelledby="contained-modal-title-vcenter"
                    centered
                >
                    <Modal.Header closeButton={true}>
                        <Modal.Title id="contained-modal-title-vcenter">
                            Предложить предсказание
                        </Modal.Title>
                    </Modal.Header>
                    {this.state.formShow ? 
                        <Form onSubmit={this.handleSubmit}>
                            <Modal.Body>
                                <Form.Group>
                                    <Form.Label>Ваша смешная шутка:</Form.Label>
                                    <Form.Control 
                                        id="suggestion"
                                        type="text"
                                        required={true}
                                        value={this.state.desc}
                                        placeholder="ну тут все ясно" 
                                        onChange={(event) => this.setState({desc: event.target.value})}
                                    />
                                </Form.Group>
                                <Form.Group>
                                    <Form.Label>Куда Вас благодарить:</Form.Label>
                                    <Form.Control
                                        id="email"
                                        type="email"
                                        required={true}
                                        value={this.state.email}
                                        placeholder="Ваш email" 
                                        onChange={(event) => this.setState({email: event.target.value})}
                                    />
                                    <Form.Text className="text-muted">
                                    Сообщим о том, как рады были получить Ваш уморительный вариант!
                                    </Form.Text>
                                </Form.Group>
                            </Modal.Body>
                            <Modal.Footer>
                                <Button type="submit" variant='outline-light' block>Не благодарите!</Button>
                            </Modal.Footer>
                        </Form>
                    :
                        <div>
                            <Modal.Body>
                                Вся команда ясеня крайне признательна Вам за неоценимый вклад в наше великое дело. <br />
                                We ❤️ you
                            </Modal.Body>
                            <Modal.Footer>
                                <Button 
                                    variant='outline-light' 
                                    onClick={this.handleHide}
                                >
                                    Понял, принял
                                </Button>
                            </Modal.Footer>
                        </div>
                        }
                </Modal>
            </div>
        )
    }
}

export default Suggestion;
