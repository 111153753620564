import React from 'react';
import axios from 'axios';
import Prediction from "./Prediction";


class PredictionContainer extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
            predictionDesc: null,
            predictionId: null,
            isLastPrediction: false,
        };
    }

    getPrediction = () => {
        let that = this;
        axios.get('/api/v1/predictions/random')
            .then(function (response) {
                if (response.status === 200) {
                    that.setState({
                        predictionId: response.data.uuid,
                        predictionDesc: response.data.desc,
                        isLastPrediction: false,
                    });
                } else {
                    that.setState({
                        predictionId: null,
                        predictionDesc: null,
                        isLastPrediction: true,
                    })
                }
            })
    };

    render() {
        return (
            <Prediction
                key={this.state.predictionId}
                predictionDesc={this.state.predictionDesc}
                predictionId={this.state.predictionId}
                isLastPrediction={this.state.isLastPrediction}
                nextPrediction={this.getPrediction}
            />
        )
    }
}

export default PredictionContainer;
