import React from 'react';
import PropTypes from 'prop-types';
import Button from 'react-bootstrap/Button';
import Rate from '../Rate';


class Prediction extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
            showRate: Boolean(props.predictionId),
        };
    }

    handleHideRate = () => {
        this.setState({showRate: false})
    };

    render() {
        let {predictionDesc, predictionId, isLastPrediction, nextPrediction} = this.props;
        let buttonText = 'Спросить у ясеня';

        if (isLastPrediction) {
            buttonText = 'Миша, все фигня. Давай по новой!';
            predictionDesc = '*Качает головой и советует ПОЙТИ ЗАНЯТЬСЯ ДЕЛАМИ*'
        } else if (predictionId) {
            buttonText = 'Спросить у ясеня ещё раз'
        }

        return (
            <div className='container-fluid d-flex align-items-center justify-content-center flex-column vh-100'>
                <Button onClick={nextPrediction} variant='secondary' size='lg'>
                    {buttonText}
                </Button>
                {predictionDesc &&
                    <div className='prediction__desc lead' variant='secondary'>
                        {predictionDesc}
                    </div>
                }
                { this.state.showRate &&
                    <Rate predictionId={predictionId} onHide={this.handleHideRate} />
                }
            </div>
        )
    }
}

Prediction.propTypes = {
  predictionDesc: PropTypes.string,
  predictionId: PropTypes.string,
  isLastPrediction: PropTypes.bool.isRequired,
  nextPrediction: PropTypes.func.isRequired,
};

export default Prediction;
