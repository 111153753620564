import React from 'react';
import axios from 'axios';
import Button from 'react-bootstrap/Button';
import { Star } from 'react-bootstrap-icons';


class Rate extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
            currentRate: 0,
        };
    }

    sendRate = (rateIndex) => {
        let that = this;
        axios.post(`/api/v1/predictions/${this.props.predictionId}/rate`, {rate: rateIndex})
        .then(function (response) {
            if (response.status === 201) {
                that.props.onHide()
            }
        })  

    }

    handleRate = (rateIndex) => {
        this.setState({currentRate: rateIndex});
        this.sendRate(rateIndex);
    }

    render() {
        const rateList = [1, 2, 3, 4, 5]

        return (
            <div className="rate" id="rate">
                {rateList.map((rateIndex) =>
                    <Star 
                        key={rateIndex}
                        className="rate__item"
                        color={ rateIndex > this.state.currentRate ? "rgba(250, 235, 215, 0.5)": "orange"}
                        size={25}
                        onClick={() => {this.handleRate(rateIndex)}}
                    />
                )}
            </div>
        )
    }
}

export default Rate;
